// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/utils/index.ts"
);
import.meta.hot.lastModified = "1726110445000";
}
// REMIX HMR END

import dayjs, { Dayjs } from 'dayjs';
import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export const handleThousandCommaSeparator = (num: number) => {
  if (!num) return '';

  const numericOnly = num?.toString()?.replace(/[^0-9]/g, '');
  return numericOnly?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const addThousandSeparator = (value: string) => {
  if (!value) return '';

  return value?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const removeThousandSeparator = (value: string) => {
  if (!value) return '';

  return value?.replace(/,(?=\d{3})/g, '');
};

export const toISODateString = (date: string | Dayjs) => {
  if (!date) return '';

  let formattedDate = '';

  try {
    formattedDate = dayjs(date, 'DD/MM/YYYY').toISOString();
  } catch (error) {
    //
  }

  return formattedDate;
};

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function convertToBoolean(
  value: string | number | boolean | undefined
): boolean {
  switch (typeof value) {
    case 'string': {
      const trueCondition = ['yes', 'true', '1'];
      const falseCondition = ['no', 'false', '0'];

      if (trueCondition.includes(value)) {
        return true;
      } else if (falseCondition.includes(value)) {
        return false;
      }
      throw new Error('Failed to convert from string');
    }
    case 'number': {
      const trueCondition = [1];
      const falseCondition = [0];

      if (trueCondition.includes(value)) {
        return true;
      } else if (falseCondition.includes(value)) {
        return false;
      }
      throw new Error('Failed to convert from number');
    }
    default: {
      throw new Error('Failed to convert');
    }
  }
}

export function convertBooleanToString(value: boolean) {
  return value ? 'true' : 'false';
}
export function convertBooleanToNumber(value: boolean) {
  return value ? 1 : 0;
}

export function getOnlyNumber(value: string) {
  return value.replace(/\D/g, '');
}
